export default function (data, disableExcel = false) {
    let window = `relatorio.leiloes.leiloes`
    !this.$uloc.printView.get(window) && this.$uloc.printView.new({
        wid: window,
        title: 'Relatórios de Leilões',
        windowClass: 'erp-print',
        contentClass: '',
        props: {
            data: data,
            orientation: 'landscape'
        }
    }, () => import('./Leiloes.vue'))
        .then((wid) => {
            let config = {}
            if (!disableExcel) {
                config.excel = (wid, val) => {
                    this.load && this.load('excel')
                }
            }
            this.$uloc.printView.listen(wid, config)
        }) // return wid
}
